*{
  margin: 0;
  padding: 0;
  font-family: "Montserrat", arial, sans-serif;
}

body{
  background-color: #2A2C2E;
}

