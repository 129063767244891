:root {
  --ex-bg-color-hue: 210;
  --ex-bg-color-saturation: 0%;
  --ex-bg-color-lightness: 0%;
  --ex-bg-color--100: #3e4044;
  --ex-bg-color--200: #35373a;
  --ex-bg-color--400: #2a2c2e;
  --ex-bg-color--700: #1d1f20;
  --ex-bg-color--1000: #17191a;
  --ex-body-bg: var(--ex-bg-color--700);
  --ex-color-black: #000;
  --ex-color-white: #fff;
  --ex-color-gray: #848484;
  --ex-color-yellow: #fff200;
  --ex-color-orange: #ffb661;
  --ex-color-grapefruit: #fc7e73;
  --ex-color-orangeInt: #ff8761;
  --ex-color-darkOrange: #f08441;
  --ex-color-green: #8dd294;
  --ex-color-dark-green: #44a569;
  --ex-color-blue: #4daef8;
  --ex-color-navi: #fff200;
  --ex-color-maincast: #f44856;
  --ex-bg-blue: var(--ex-color-blue);
  --ex-bg-gray: var(--ex-bg-color--100);
  --ex-color-red: #ff6464;
  --ex-color-violet: #af7fff;
  --ex-color-violet-blue: #5957f7;
  --ex-color-violet--dark: #7056e1;
  --ex-color-purple: #8851f6;
  --ex-color-primary: var(--ex-color-white);
  --ex-color-secondary: var(--ex-color-gray);
  --ex-border-color: var(--ex-bg-color--400);
  --ex-font-family: "Montserrat", arial, sans-serif;
  --ex-font-family--currencies: "dmarket-currencies", arial, sans-serif;
  --ex-letter-spacing: .46px;
  --ex-radius--round: 100px;
  --ex-radius--primary: 2px;
  --ex-transition-primary: all .25s ease-in-out;
  --ex-transition-secondary: .15s ease-in-out;
  --ex-bg-color--backdrop: hsla(var(--ex-bg-color-hue), calc(6% + var(--ex-bg-color-saturation)), 10%, .7);
  --ex-field-bg-color: var(--ex-bg-color--400);
  --ex-field-color--disabled: var(--ex-color-secondary);
  --ex-field-size: 48px;
  --ex-container-padding: 24px;
  --ex-content-width: 1280px;
  --ex-header-height: 60px;
  --ex-header-open-height: 1px;
  --ex-header-border-size: 2px;
  --ex-headerBannner-height: 65px;
  --ex-banner-height: 70px;

}
:root {
  --seoArea-margin: 16px;
  --seoArea-width: 365px;
  --seoArea-width--minus: -365px;
  --seoArea-topGap: 16px;
} 
:root {
  --ex-search-sizeSm: 166px;
  --ex-search-size: 255px;
}

:root {
  --auth-color: var(--ex-color-blue);
  --auth-bg-color: var(--ex-bg-color--400);
  --ex-auth-width: 100%;
}
:root {
  --assetPreviewParam-color: var(--ex-color-primary);
}
:root {
  --assetPreviewParam-color: var(--ex-color-primary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.c-historyDialog__header {
  position: relative;
}

@media (min-width: 768px)
{.c-historyDialog__tabs {
    display: inline-flex;
}
}

.c-historyDialog__close {
  --ex-btn-close-width: 24px;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 10;
  cursor: pointer;
}
.mat-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
} 

textarea, input, button {
  font-family: "Montserrat", arial, sans-serif;;
  outline: 0 none;
  color: inherit;
}

.c-historyDialog__tab:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  width: 0;
  height: 1px;
  opacity: 0;
  background: #8dd294;
  transition: var(--ex-transition-secondary);
}
button.c-historyDialog__tab.is-active{
  border-bottom: 1px solid;
}

button {
  background-color: transparent;
  border: 0 none;
  font: inherit;
  padding: 0;
}
button{
  color: #fff!important;
}
textarea, input, button {
  outline: 0 none;
  color: inherit;
}


.c-historyDialog__tab {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: var(--min-height-dialog-switch-in-header);
  padding: 24px;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: .15s ease-in-out;
  cursor: pointer;
}

.c-historyDialog__tab:hover:after, .c-historyDialog__tab.is-active:after {
  width: 100%;
  opacity: 1;
}

.c-historyDialog__tab:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  width: 0;
  height: 1px;
  opacity: 0;
  background: #8dd294;
  transition: .15s ease-in-out;
}


.c-dialog__scroll {
  flex: 1;
  overflow: auto;
}

[_nghost-dmarket-c277] {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.c-history {
  --ex-image-operation-size: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: #2a2c2e;
  border-radius: 8px;
  margin-bottom: 30px;
  font-size: 12px;
}

.c-historyFeed__note {
  padding: 4px 4px 0;
  background-color: #323436;
}

.\441-exchangeNotification__text {
  font-size: 12px;
  line-height: 170%;
}
.\441-exchangeNotification__header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.\441-exchangeNotification__close {
  position: absolute;
  right: 16px;
  color: #fff;
  z-index: 10;
}


.\441-exchangeNotification--canBeClosed {
  padding-right: calc(16px * 3);
}

.\441-exchangeNotification__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 10px;
}

.\441-exchangeNotification__title--warn {
  color: #ffb661;
}

.\441-exchangeNotification__title {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  margin-right: 15px;
}

.\441-exchangeNotification {
  --notificationPadding: 16px;
  position: relative;
  display: block;
  min-height: 46px;
  padding: 15px var(--notificationPadding);
  color: #fff;
  background: #2a2c2e;
  border-radius: calc(2px * 2);
  margin: 5px 0 20px;
}


.c-history__table {
  display: table;
  border-collapse: separate;
  width: 100%;
  margin-top: 20px;
  font-size: 11px;
}

.c-history__row {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  min-height: 35px;
  align-items: center;
  position: relative;
}
 
.c-history__cell {
  display: table-cell;
  padding: 4px 16px;
  height: 64px;
  vertical-align: middle;
  text-align: center;
}
.c-history__row--head .c-history__cell {
  border-bottom: 1px solid rgba(255,255,255,.1);
  padding: 12px 16px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  max-width: 120px;
}

@media (min-width: 768px)
{.c-history__row:nth-child(even):not(.c-history__row--head) {
    background-color: #ffffff0a;
}
}
.c-history__cell--date {
  color: var(--ex-color-white);
  opacity: .5;
  min-width: 95px;
}

.c-history__cell--date > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 120px;
  margin: 0 auto;
}

.c-history__cell {
  display: table-cell;
  padding: 4px 16px;
  height: 64px;
  vertical-align: middle;
}
.c-history__cellIcon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 2px;
}
 

div.c-dialog__scroll > app-history > div > div.c-history__table.ng-star-inserted > app-history-item{
  
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  min-height: 35px;
  align-items: center;
  position: relative;
}

div.c-dialog__scroll > app-history > div > div.c-history__table.ng-star-inserted > app-history-item:nth-child(2n) {
    background-color: #323436;
}

.c-history__cell--date {
  color: #fff;
  opacity: .5;
  min-width: 95px;
} 


.c-history__cellMoney--credit {
  color: #ff6464;
}

.c-history__cellMoney {
  text-align: center;
}


.c-history__cellMoney--debit {
  color: #8dd294;
}

.c-history__cellMoney--debit:before {
  content: "+";
}
.c-history__cellMoney:before {
  content: "\a0\a0";
}


[class^=o-currencies--], [class*=" o-currencies--"] {
  font-family: var(--ex-font-family);
  speak: none;
  font-style: normal;
  font-weight: 500;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.o-currencies--USD:before {
  content: "$";
}

.c-history__cellMoney--debit:before {
  content: "+";
}
.c-history__cellMoney--credit:before {
    content: "-";
}
 

.c-history__cellStatus--success {
  --historyStatus-color: #8dd294;
}

.c-history__cellStatus {
  --historyStatus-color: var(--historyStatus-color);
  --historyStatus-color--success: #8dd294;
  --historyStatus-color--pending: var(--ex-color-orange);
  --historyStatus-color--failed: var(--ex-color-red);
  --historyStatus-color--canceled: #87453a;
  width: 8px;
  min-width: 8px;
  height: 8px;
  display: block;
  margin: auto;
  background-color: #8dd294;
  border-radius: 100px;
}




 
@media (max-width: 767px){
  
.c-dialogHeader--back {
  padding-left: 46px;
}
}

.c-historyDialog__header {
  position: relative;
}


o-icon {
  display: inline-flex;
  width: var(--ex-icon-size);
  height: var(--ex-icon-size);
}

.c-history__cellEllipsis {
  display: block;
}
.c-history__cellEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.o-icon svg {
  width: inherit;
  height: inherit;
  fill: currentColor;
}

.c-history__cellTransaction {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}




[_nghost-dmarket-c192] {
  width: 100%;
}
.c-history__pagination {
  margin-top: auto;
}
* {
  box-sizing: border-box;
  outline: none;
}


.c-pagination {
  width: 100%;
  padding: 30px 0 0;
  text-align: center;
}

@supports not (-webkit-overflow-scrolling: touch){
  
.mat-flat-button.mat-green {
  --ex-mat-button-background: linear-gradient(253.3deg, #75cbbe 0%, #63ca7c 100%);
} 
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0 0 #0003, 0 0 #00000024, 0 0 #0000001f;
}

.mat-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.c-pagination__load {
  --ex-mat-button-width: 225px;
}
.mat-flat-button .mat-button-wrapper {
  display: inline-block;
  vertical-align: middle;
}
.mat-flat-button * {
  pointer-events: none;
}

@supports not (-webkit-overflow-scrolling: touch){
  
.mat-flat-button.mat-green {
  --ex-mat-button-background: linear-gradient(253.3deg, #75cbbe 0%, #63ca7c 100%);
}
.mat-button-ripple.mat-ripple, .mat-button-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}

}


.c-pagination__list {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-top: 30px;
}

.mat-flat-button {
  display: inline-flex!important;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: linear-gradient(253.3deg, #75cbbe 0%, #63ca7c 100%);
  width: 225px!important;
  margin: 0!important;
  padding: 0 16px!important;
  max-width: 100%; 
  border-radius: 2px;
  line-height: 1!important;
  font-weight: 400;
  font-size: 16px;
}

.mat-flat-button:hover {
  color: white;
  box-shadow: inset 0 -70px #0000001a;
}


.c-pagination__item {
  margin: 0 10px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: var(--ex-transition-primary);
}


c-pagination__arrow--disabled {
  opacity: .3;
  cursor: default;
}
.c-pagination__arrow {
  cursor: pointer;
  margin: 0;
  outline: none;
  min-width: 7px;
}
.c-pagination__link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: var(--ex-transition-primary);
}


.mat-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
  text-decoration: none;
}
.mat-icon, .material-icons, .c-dialogCounter__button--cantExchange:after, .mat-form-field.mat-form-field-appearance-fill .mat-select-arrow-wrapper .mat-select-arrow:after {
  -webkit-user-select: none;
  user-select: none;
  display: var(--mat-icon-display)!important;
  width: var(--mat-icon-size)!important;
  height: var(--mat-icon-size)!important;
  font-size: var(--mat-icon-size)!important;
  color: var(--mat-icon-color)!important;
  font-family: Material Icons,serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.c-pagination__item--active {
  border: 1px solid #8dd294;
  color: #8dd294;
  cursor: default;
}


.c-historyDialog__close:hover {
  opacity: 0.5;
  transition: 0.5s ease-out;
}
@media screen and (max-width: 992px) {
.c-history__table{
  overflow: auto;
  display:block;
}
.c-historyDialog__tabs{
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: inline-flex;
}
}

.c-historyDialog__tab:hover{
  color: #8dd294!important;
}

 
.c-pagination__list {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-top: 30px;
}
ol, ul {
    list-style: none;
}


.c-pagination__item {
  margin: 0 10px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: all .25s ease-in-out;
}

.c-pagination__arrow--disabled {
  opacity: .3;
  cursor: default;
}
.c-pagination__arrow {
  cursor: pointer;
  margin: 0;
  outline: none;
  min-width: 7px;
}

.c-pagination__link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
  transition: all .25s ease-in-out;
}

.c-pagination__link:hover{
  color: #8dd294;
}
.c-pagination__item--active .c-pagination__link  {
  border: 1px solid #8dd294;
  color: #8dd294;
  cursor: default;
}
.c-pagination__item {
  margin: 0 10px;
  width: 34px;
  height: 34px;
  min-width: 34px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: all .25s ease-in-out;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
  
  /*! CSS Used from: https://cdn-mp.dmarket.com/prod/v1-224-2/styles.7aa3af128a903fa8.css */
div,i,label{margin:0;padding:0;border:0;font-size:100%;font-weight:inherit;vertical-align:baseline;}
button{background-color:transparent;border:0 none;font:inherit;padding:0;}
*{box-sizing:border-box;outline:none;}
input,button{font-family:var(--ex-font-family);outline:0 none;color:inherit;}
i svg{max-width:100%;max-height:100%;}
.c-assetFilters__item{-webkit-user-select:none;user-select:none;}
  
.o-filter{position:relative;display:inline-flex;align-items:center;justify-content:center;padding:0;min-width:var(--ex-field-size);height:var(--ex-field-size);background:var(--ex-bg-color--400);border-radius:var(--ex-radius--primary);color:var(--ex-color-primary);cursor:pointer;}
.o-filter:hover{box-shadow:inset 0 -70px #0000001a;}
.o-filter__icon{width:24px;min-width:24px;height:24px;position:relative;display:inline-flex;align-items:center;justify-content:center;cursor:pointer;}
.o-filter__search{display:inline-flex;align-items:center;width:0;border-radius:var(--ex-radius--primary);overflow:hidden;}
.o-filter__searchButton{width:var(--ex-field-size);min-width:var(--ex-field-size);height:var(--ex-field-size);position:absolute;left:-1px;top:0;color:var(--ex-color-primary);background:var(--ex-bg-color--400);z-index:2;cursor:pointer;}
.o-filter__searchInput{position:absolute;padding:0;left:0;max-width:100%;height:100%;min-width:100%;border:none;background:var(--ex-bg-color--400);-webkit-appearance:none;appearance:none;transition:var(--ex-transition-primary);color:transparent;font-size:14px;}
.o-filter__searchClear{display:flex;align-items:center;justify-content:center;position:absolute;background:var(--ex-bg-color--400);height:100%;width:var(--ex-field-size);right:0;top:50%;transform:translateY(-50%) scale(0);opacity:0;transition:var(--ex-transition-primary);color:currentColor;cursor:pointer;}
.o-filter__searchClear .o-filter__icon{width:22px;min-width:22px;height:22px;}
.o-filter--search{min-width:var(--ex-search-size);overflow:hidden;transition:var(--ex-transition-primary);width:var(--ex-field-size);border:1px solid var(--ex-bg-color--400);}
@media (max-width: 991px){
.o-filter--search{min-width:180px;margin-right: 7px;}
}
.o-filter--search{margin-right: 7px;}
.o-filter--search .o-filter__searchInput{padding:0 10px 0 var(--ex-field-size);color:currentColor;}

/* [type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
} */

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}


.o-filter--search.o-filter--active{
  min-width: var(--ex-search-size);
  border-color: var(--ex-color-green)!important;

}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container .cdk-overlay-dark-backdrop {
  background: var(--ex-bg-color--backdrop);
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.32);
}
.cdk-overlay-backdrop {
  position: absolute;
  inset: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .4s cubic-bezier(.25,.8,.25,1);
  opacity: 0;
}

.cdk-overlay-container .cdk-global-overlay-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}


element.style {
  justify-content: center;
  align-items: center;
}
.cdk-overlay-container .cdk-global-overlay-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
}
.\441-filtersArea__content, .c-seoAreaContent__table, .c-seoAreaContent pre, .\441-seoArea__content, .\441-seoArea, .\441-filtersArea, .c-assets, .c-footer, .c-targetsSearch__list, .c-dialogTerms, .c-dialogTabs__content, .c-dialogPolicy, .c-dialogRedeem__list, .c-dialogWithdraw__list, .c-dialogFilters__games, .c-dialogExchange, .c-dialog__body, .c-dialog__scroll, .c-dialog__list, .\441-countryPick__list--scroll, .o-select__list, .c-chosenFilters, .mat-drawer-container .mat-drawer-content, .mat-select-panel, .cdk-overlay-container .cdk-global-overlay-wrapper, body {
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  scrollbar-width: thin;
}
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.approve{
  width: auto!important;
  padding: 0!important;
  height: 30px;
  min-width: 40px;
}

.ng-update{
  padding: 0px!important;
  text-align: center;
}

.o-select__item {
  display: flex; 
  align-items: center;
  justify-content: flex-start;
  min-height: 36px;
  padding: 5px 0;
  transition: var(--ex-transition-primary);
  cursor: pointer;
}

.o-select__item--checkbox:before {
  content: "";
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: 6px;
  border: 1.5px solid var(--ex-color-green);
  border-radius: 2px;
}
.o-select__item--text {
  color: #fff;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
}

.o-select__item--active.o-select__item--checkbox:before {
  background: var(--ex-color-green) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNiAyNiI+ICA8cGF0aCBkPSJNLjMgMTRjLS4yLS4yLS4zLS41LS4zLS43cy4xLS41LjMtLjdsMS40LTEuNGMuNC0uNCAxLS40IDEuNCAwbC4xLjEgNS41IDUuOWMuMi4yLjUuMi43IDBMMjIuOCAzLjNoLjFjLjQtLjQgMS0uNCAxLjQgMGwxLjQgMS40Yy40LjQuNCAxIDAgMS40bC0xNiAxNi42Yy0uMi4yLS40LjMtLjcuMy0uMyAwLS41LS4xLS43LS4zTC41IDE0LjMuMyAxNHoiIGZpbGw9IiMyNjJhMmYiLz48L3N2Zz4=) no-repeat center;
  background-size: 85%;
}
.react-calendar{
  background-color: #35373A;
  min-width: 300px;
  max-width: 400px;
}
.react-calendar__tile{
  padding: 5px;
}
.react-calendar__tile:hover{
  border:1px solid gray;
  border-radius: 50%;
  padding: 0!important;
  margin: 0!important;
}
.react-calendar__tile--active{
  border: 1px solid gray;
  border-radius: 50%;
  padding: 0!important;
  margin: 0!important;
}

.react-calendar__month-view__weekdays{
  padding: 10px;
  border-bottom: 1px solid gray;
}
.react-calendar__month-view__weekdays__weekday{
  color:gray;
}
.react-calendar__month-view__days{
  margin-top: 10px;
}
.react-calendar__tile.react-calendar__month-view__days__day{
  padding: 5px;
}

.react-calendar__navigation{
  min-height: 50px;
  text-align: center;
  padding: 15px;
}
abbr[title] {
  text-decoration: none;
}

.react-calendar__navigation__label{
  float: left;
}
.react-calendar__navigation__arrow{
  padding: 0px 5px;
}

.react-calendar__month-view__days__day:disabled > abbr{
  color: gray;
}


@media (max-width: 991px)
{
.c-authDialog__header[_ngcontent-dmarket-c3091065540] {
    justify-content: center;
    border-bottom: 2px solid #36373b;
}}

.c-authDialog__header[_ngcontent-dmarket-c3091065540] {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 80px;
  padding: 0 16px;
  z-index: 3;
}

.c-authDialog__close[_ngcontent-dmarket-c3091065540] {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -12px;
  z-index: 10;
}
.c-authDialog__dmLogo[_ngcontent-dmarket-c3091065540] {
  height: 24px;
}